import React, { useContext } from "react";
import { AuthContext } from '../../Components/auth/authContext';

const HomePage: React.FC = () => {
  const currentUser = useContext(AuthContext);

  return (
    <div>
      <h1>Home Page</h1>
      <span>User: {currentUser?.email}</span>
      <br/>
      <span>UserID: {currentUser?.uid}</span>
      {/* Add your login form here */}
    </div>
  );
};

export { HomePage };
