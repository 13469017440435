import React, { ReactNode } from 'react';
import Header from './Header/header';
import Sidebar from './Sidebar/sidebar';
import SidebarOverlay from './Sidebar/sidebarOverlay';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
      <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
        <Header />
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <Sidebar />
          <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
              {children}
            </div>
          </div>
        </div>
      </div>
      <SidebarOverlay />
    </div>
  );
};

export default MainLayout;
