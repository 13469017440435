import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

// Create an instance of Axios Mock Adapter
const mock = new MockAdapter(axios);

// Mock the /profile API response
mock.onPost('/profile').reply(200, {
    message: 'Profile created successfully'
});

export interface ProfilePayload {
    userId: string;
    email: string;
    // Add any other profile data you need here
}

export const createProfile = async (profilePayload: ProfilePayload) => {
    try {
        const response = await axios.post('/profile', profilePayload);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to create profile');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
